import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Header from "../components/header"

import SignupForm from "../components/signupform"
import Posts from "../components/posts"

import PostItem from "../components/postitem"
import "../components/css/posts.css"

import { graphql } from "gatsby"
import { useStaticQuery } from "gatsby"

const About = () => {


  return (

  <Layout>
    <SEO title="About Us" />
    <Header/>


    <div class="section whatwedo">
      <div class="container">
      </div>
    </div>

    <div class="section mission">
      <div class="container">
        <div class="row">
          <div class="one-half column">
            <h3>Our vision</h3>
            <p>
              Her Financial Journey sees a world where women are financially independent, are highly skilled with money, take lead roles in organisations and where there’s equal wealth between the genders.
            </p>
          </div>
          <div class="one-half column">
            <h3>Our mission</h3>
            <p>
              We encourage women to strive for increased personal wealth, career growth and financial success, through informative and inspiring posts, stories and how-to guides.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="section mission">
      <div class="container">
        <div class="row">
          <div class="column">
            <h3>The gender wealth gap</h3>
            <p className="desc">
            Despite remarkable progress in the gender-equality space, inequality still prevails. All over the world, many women are still paid less, own less and remain financially dependent on men.
            </p>
          </div>
        </div>

        <div class="row">
          <div class="one-half column">
            <p>
            Wealth provides the basis of our financial security, and money is a tool and an enabler in the world in which we live. If men own the majority of the world’s wealth, the gender wealth gap will continue to prevent women from reaching parity with men.
            </p>
          </div>
          <div class="one-half column">
            <p>
            Not only is the endeavour to close the gender wealth gap the right thing to do, it would also mean a higher national economic output through the increase in women’s economic activity.
            </p>
          </div>
        </div>

        <div class="row">
          <div class="column">
            <p>
            Read about the <Link to="/blog/the-gender-wealth-gap">Gender Wealth Gap</Link> and the reasons why we believe women’s wealth is a vitally important moral and economic issue.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="section mission">
      <div class="container">
        <div class="row">
          <div class="column">
            <h3>What we do</h3>
            <p class="desc">
              Are you a female who strives for financial success and advancement?  At Her Financial Journey, we believe financial success and advancement comes in many forms, but is always aligned with personal growth and development.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="one-half column">
            <p>
            Her Financial Journey is a campaign for women to take charge of their finances, increase their financial literacy and ambitiously pursue personal wealth, fulfilling careers and success.
            We showcase and celebrate the achievements and contributions made by financially successful women.
            </p>
          </div>
          <div class="one-half column">
            <p>
            Through a wide range of informative, inspiring and engaging stories and articles, we are dedicated to encouraging and motivating women along a journey to a wealthier version of themselves.
            </p>
          </div>
        </div>
      </div>
    </div>



    <div class="section aboutus">
      <div class="container">
        <div class="row">
          <div class="two-thirds column">
            <h3>More about us</h3>
            <p class="desc">
              Her Financial Journey is an organisation, designed to promote the advancement and success of women in their financial journey.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="two-thirds column">
            <p>
              <a href="https://www.linkedin.com/in/sara-trimboli">Sara Trimboli</a> is the founder of Her Financial Journey,
              and an advocate for all women who wish to advance their
              financial position and create wealth.
            </p>
            <p>
              Mother of three young children, and wife to a loving husband,
              Sara saw inequality in the financial position of women and men,
              and chose to actively pursue change.
            </p>
            <p></p>

          </div>
          <div class="one-third column">
            <p></p>
          </div>
        </div>
      </div>
    </div>





  </Layout>

  )
}

export default About
